/** @jsx jsx */
import React from "react"
import { jsx, Link as TLink } from "theme-ui"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import {FaLinkedin, FaGithub, FaTwitter, FaInstagram} from "react-icons/fa"

const HeaderExternalLinks = () => {
  const { externalLinks } = useMinimalBlogConfig()

  return (
    <React.Fragment>
      {externalLinks && externalLinks.length > 0 && (
        <div sx={{fontSize: [1, `18px`] }}>
          {externalLinks.map((link) => (
            <TLink key={link.url} href={link.url}>
              {link.name=='GitHub' && (<FaGithub sx={{ mr: 3, transform:' scale(1.4, 1.4)'}}/>)}
              {link.name=='LinkedIn' && (<FaLinkedin sx={{ mr: 3, transform:' scale(1.4, 1.4)'}}/>)}
              {link.name=='Twitter' && (<FaTwitter sx={{ mr: 3, transform:' scale(1.4, 1.4)'}}/>)}
              {link.name=='Instagram' && (<FaInstagram sx={{ mr: 3, transform:' scale(1.4, 1.4)'}}/>)}

              {/* {link.name} */}
            </TLink>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

export default HeaderExternalLinks
