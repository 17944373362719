/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, Box } from "theme-ui"
import replaceSlashes from "../utils/replaceSlashes"
import useSiteMetadata from "../hooks/use-site-metadata"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import {useSpring, animated} from 'react-spring'

const HeaderTitle = () => {
  const { siteTitle, siteImage } = useSiteMetadata()
  const { basePath } = useMinimalBlogConfig()
  const props = useSpring({config: { duration: 1250 }, opacity: 1, from: {opacity: 0}})

  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteTitle} - Back to home`}
      sx={{ color: `heading`, textDecoration: `none` }}
    >
      <div sx={{display: 'flex', alignItems: 'center'}}>
        <img sx={{maxWidth: 10, mr: 3, mb: '-1%'}} src={siteImage}/>
        <h1 sx={{display: 'inline', my: 0, fontWeight: `medium`, fontSize: [2, 3, 4] }}>{siteTitle}</h1>
      </div>
     
    </Link>
  )
}

export default HeaderTitle
