/** @jsx jsx */
import React from "react"
import { Global } from "@emotion/core"
import { Box, Container, jsx} from "theme-ui"
import "typeface-ibm-plex-sans"
import SEO from "./seo"
import Header from "./header"
import Footer from "./footer"
import Author from "./author"
import CodeStyles from "@lekoarts/gatsby-theme-minimal-blog/src/components/../styles/code"
import SkipNavLink from "./skip-nav"
import StickyBox from "react-sticky-box";

type LayoutProps = { children: React.ReactNode; className?: string }

const Layout = ({ children, className = `` }: LayoutProps) => (
  <React.Fragment>
    <Global
      styles={(theme) => ({
        "*": {
          boxSizing: `inherit`,
        },
        html: {
          WebkitTextSizeAdjust: `100%`,
        },
        img: {
          borderStyle: `none`,
        },
        pre: {
          fontFamily: `monospace`,
          fontSize: `1em`,
        },
        "[hidden]": {
          display: `none`,
        },
        "::selection": {
          backgroundColor: theme.colors.text,
          color: theme.colors.background,
        },
        a: {
          transition: `all 0.3s ease-in-out`,
          color: `text`,
        },
      })}
    />
   
    <SEO />
    <SkipNavLink>Skip to content</SkipNavLink>
    <Container>
    <StickyBox offsetTop={0} offsetBottom={20}>
        <Header />
      </StickyBox>
      <Box id="skip-nav" sx={{ ...CodeStyles }} className={className}>
        {children}
      </Box>
        <Author/>
      <Footer />
    </Container>
   
  </React.Fragment>
)

export default Layout
