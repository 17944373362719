/** @jsx jsx */
import { jsx, Alert } from "theme-ui"
import useSiteMetadata from "../hooks/use-site-metadata"
import {useSpring, animated} from 'react-spring'

const author = () => {
  const { authorImage } = useSiteMetadata()
  const props = useSpring({config: { duration: 1250 }, opacity: 1, from: {opacity: 0}})


  return (
    <animated.div style={props}>     
      <Alert sx={{ p:2, bg: 'background'}}>
      <div sx={{maxWidth: '500px', display: 'flex'}}>
        <div sx={{mr:'3', display: 'flex', alignItems: 'center'}}>
          <img sx={{maxWidth: '80px', borderRadius: `5%`}} src={authorImage}/>          
        </div>
        <div>
          <p sx={{color:'secondary'}}>The Dev<br/>Software Engineer<br/>BIT. Information Systems Engineering</p>
        </div>
      </div>
    </Alert>   
    </animated.div>
  )
}

export default author
